import { Component, OnInit } from "@angular/core";
import { Router, Route, ActivatedRoute } from "@angular/router";
import {
  AppSettings,
  AuthenticationService,
  UserService,
  HelperService,
} from "../../../services/_services";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public admin: any = { name: "", email: "", password: "" };
  public loading: any = false;
  public showErrorAlert: any = false;

  constructor(
    private userService: UserService,
    private helperService: HelperService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    if (this.authenticationService.get_currentUserValue()) {
      // Redirect to dashboard if already logged in
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {}

  closeAlert() {
    this.showErrorAlert = false;
  }
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      // Prevent default form submission behavior
      event.preventDefault();

      // Call login function
      this.login();
    }
  }
  login() {
    // Close previous errors
    this.closeAlert();

    // Show Loader
    this.loading = true;
    let url = AppSettings.API_ENDPOINT + "login";
    this.helperService
      .httpPostRequests(url, this.admin)
      .then((user) => {
        console.log("heree");
        console.log("user: ", user);
        sessionStorage.setItem("token", user.access_token);
        this.authenticationService.setCurrentUserValue(user.user);
        this.router.navigate(["/blogs"]);

        // Hide Loader
        this.loading = false;
      })
      .catch((error) => {
        console.log("error: ", error);

        // Show error message
        this.showErrorAlert = true;

        // Hide Loader
        this.loading = false;
      });
  }
}
