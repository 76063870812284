import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AuthenticationService,
  UserService,
  SEOService,
  AppSettings,
  HelperService,
} from "../../services/_services";
import { Location } from "@angular/common";

import { Router, Route, ActivatedRoute, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-new-header",
  templateUrl: "./new-header.component.html",
  styleUrls: ["./new-header.component.css"],
})
export class NewHeaderComponent implements OnInit {
  @ViewChild("sideMenu", { static: false }) sideMenu: any;
  @ViewChild("mainMenu", { static: false }) mainMenu: any;

  public menus: any = [
    // { name: "Articles",                   route: "/services",              active: true,  onlyAdmin: false  },
    { name: "Blogs", route: "/blogs", active: false, onlyAdmin: false },
    {
      name: "State Page",
      route: "/state-page",
      active: false,
      onlyAdmin: false,
    },
    {
      name: "Law Page",
      route: "/law-page",
      active: false,
      onlyAdmin: false,
    },
    {
      name: "Airline Page",
      route: "/airline-page",
      active: false,
      onlyAdmin: false,
    },
    // {
    //   name: "Service Page",
    //   route: "/service-page",
    //   active: false,
    //   onlyAdmin: false,
    // },
    // { name: "Guides",                     route: "/guides",                active: false, onlyAdmin: false },
    { name: "Writers", route: "/writers", active: false, onlyAdmin: false },
    { name: "Users", route: "/users", active: false, onlyAdmin: true },
    { name: "Account", route: "/account", active: false, onlyAdmin: false },
  ];
  currentRoute: any;

  public activeUser: any;
  public activeRoute: any;
  public routerSubscription: any;

  constructor(
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SEOService,
    private helperService: HelperService,
    private _location: Location
  ) {
    this.routerSubscription = this.router.events.subscribe((val) => {
      // Get Current User
      this.activeUser = this.authenticationService.get_currentUserValue();

      if (val instanceof NavigationEnd) {
        this.activeRoute = val.url;

        if (val.url == "/") {
          this.menus[0].active = true;
        } else {
          for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].route == val.url) this.menus[i].active = true;
            else this.menus[i].active = false;
          }
        }

        // Only admin can manage users
        if (
          (this.activeRoute === "/users" || this.activeRoute === "/bin") &&
          this.activeUser.role !== "Admin"
        )
          this.router.navigate(["/"]);
      }
    });
  }

  ngOnInit() {}

  showSideMenu() {
    this.sideMenu.nativeElement.classList.add("show");
    setTimeout(() => {
      this.mainMenu.nativeElement.classList.add("show");
    }, 10);
  }
  hideSideMenu() {
    this.mainMenu.nativeElement.classList.remove("show");
    this.sideMenu.nativeElement.classList.remove("show");
  }

  logout() {
    this.authenticationService.simpleLogout();
    this.router.navigate(["/login"]);
  }
}
